
// @ts-nocheck
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import Container from "@/components/Container.vue";
import ListingCategory from "@/components/ListingCategory.vue";
import PwaCard from "@/components/PwaCard.vue";
import WhatsappBanner from "@/components/WhatsappBanner.vue";

export default Vue.extend({
  name: "CompanyListings",
  components: {
    Container,
    ListingCategory,
    PwaCard,
    WhatsappBanner,
  },
  data() {
    return {
      companyCategories: null,
    };
  },
  computed: {
    ...mapGetters(["countryCode"]),
    ...mapState(["jsonCompanyResults"]),
    companyCategoriesWithFeatured() {
      return this.companyCategories?.filter(
        (c) => c.companies.featured !== undefined,
      );
    },
  },
  watch: {
    countryCode: {
      handler(newVal) {
        if (!newVal) return;
        import(`@/static/${newVal}/companies.json`).then((module) => {
          this.companyCategories = module.default;
        });
      },
      immediate: true,
    },
  },
});
