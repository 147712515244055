
import { Card } from "@num/component-library";
import Vue from "vue";

import LogoIcon from "@/assets/logo-icon.svg";

import { getMobileOperatingSystem } from "@/utils/utils";

import { MODE } from "@/app.config";

export default Vue.extend({
  components: {
    Card,
    LogoIcon,
  },
  data() {
    return {
      MODE,
      os: getMobileOperatingSystem(),
    };
  },
});
