
// @ts-nocheck
import Vue from "vue";

import ListingItem from "@/components/ListingItem.vue";

export default Vue.extend({
  name: "ListingCategory",
  components: {
    ListingItem,
  },
  props: {
    category: Object,
    jsonCompanyResults: Object,
    index: Number,
  },
});
